import React, { Component } from 'react'
import { updateObject, checkValidity } from '../../../shared/utility'
import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import Form from '../../UI/Form/Form'
import { Link } from 'gatsby'
import Checkbox from '../../UI/Checkbox/Checkbox'

class SignUp extends Component {
  state = {
    form: {
      name: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Your Name'
        },
        value: '',
        label: 'Name',
        validation: {
          required: true,
          minLength: 2,
          maxLength: 13
        },
        helperText: 'Minimum 2, maximum 13 characters',
        valid: false,
        touched: false
      },
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'example@gmail.com'
        },
        value: '',
        label: 'Email',
        validation: {
          required: true,
          isEmail: true
        },
        helperText: 'Not a valid email',
        valid: false,
        touched: false
      },
      password: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Password'
        },
        value: '',
        label: 'Password',
        validation: {
          required: true,
          minLength: 6
        },
        helperText: 'At least 6 characters',
        valid: false,
        touched: false
      },
      password_2: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Password'
        },
        value: '',
        label: 'Confirm Password',
        validation: {
          required: true,
          minLength: 6,
          comparePass: true
        },
        helperText: `Passwords don't match`,
        valid: false,
        touched: false
      }
    },
    agreeToTerms: true,
    formIsValid: false
  }

  signupHandler = () => {
    const formData = {}

    for (let elementID in this.state.form) {
      formData[elementID] = this.state.form[elementID].value
    }

    this.props.onSignup(formData.name, formData.email, formData.password)
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const updateFormElement = updateObject(this.state.form[inputIdentifier], {
      value: event.target.value,
      valid: checkValidity(
        event.target.value,
        this.state.form[inputIdentifier].validation,
        { pass: this.state.form.password.value }
      ),
      touched: true
    })

    const updatedForm = updateObject(this.state.form, {
      [inputIdentifier]: updateFormElement
    })

    let formIsValid = true

    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid
    }

    this.setState({
      form: updatedForm,
      formIsValid: formIsValid
    })
  }

  closeErrorHandler = () => this.props.eraseError()

  checkboxHandler = () => {
    this.setState(prevState => {
      return {
        agreeToTerms: !prevState.agreeToTerms
      }
    })
  }

  render() {
    const formElements = []

    for (let key in this.state.form) {
      formElements.push({
        id: key,
        config: this.state.form[key]
      })
    }

    if (this.props.isAuthenticated) navigate(this.props.redirectPath)

    let error = null
    let errorMessage = null

    if (this.props.error) {
      error = true
      errorMessage =
        this.props.error.code === 400
          ? 'Unfortunately an account with this email already exists.'
          : this.props.error
    }

    let links = [
      <Link to="/pmp-training-sign-in">Already have an account? Sign In</Link>
    ]

    let checkbox = (
      <Checkbox
        text={
          <Link to="/pmp-sample-questions-terms-of-use">
            I agree to the terms of use
          </Link>
        }
        checked={this.state.agreeToTerms}
        onChange={this.checkboxHandler}
      />
    )

    return (
      <Form
        error={error}
        errorClick={this.closeErrorHandler}
        errorMessage={errorMessage}
        title="Sign Up"
        inputs={formElements}
        onSubmit={this.signupHandler}
        chageHandler={this.inputChangedHandler}
        spinner={this.props.loading}
        disabled={!this.state.formIsValid || !this.state.agreeToTerms}
        links={links}
        checkbox={checkbox}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    isAuthenticated: state.user.id !== null,
    redirectPath: state.auth.authRedirectPath,
    error: state.auth.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSignup: (name, email, password) =>
      dispatch(actions.signup(name, email, password)),
    eraseError: () => dispatch(actions.authEraseError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
