import React from 'react'
import classes from './Checkbox.module.css'

import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'

const CustomCheckbox = withStyles({
  root: {
    color: '#604f93',
    '&$checked': {
      color: '#604f93'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />)

export default props => (
  <div className={classes.Checkbox}>
    <span>
      <CustomCheckbox checked={props.checked} onChange={props.onChange} />
      <span className={classes.Link}>{props.text}</span>
    </span>
  </div>
)
