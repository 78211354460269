import React from 'react'
import Layout from '../hoc/Layout/Layout'
import SignUp from '../components/Pages/SignUp/SignUp'
import SEO from '../components/seo'

export default () => {
  return (
    <Layout>
      <SEO
        title="PMP Exam Simulator | Sign Up | PMP Training"
        keywords={[`PMP`, `PMP Exam`, `Project Management`]}
        description="PMP Preparation with PMP Exam Simulator. Sign Up for PMP Mock Exam, Statistics, Performance Chart."
      ></SEO>
      <SignUp />
    </Layout>
  )
}
